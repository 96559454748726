// Services.jsx

import { Container, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ServiceSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#000000',
  padding: '3%',
}));

const ServiceItem = styled('div')({
  textAlign: 'center',
  padding: '10%',
});

const ListContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '10%',
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '30%',
    height: '3%',
    backgroundColor: '#ffffff',
  },
  '&::before': {
    top: '-6%',
  },
  '&::after': {
    bottom: '-6%',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '15%',
  },
}));

const Service = () => {
  return (
    <ServiceSection id="services-section">
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          sx={{ marginBottom: '40px', color: '#ffffff', fontSize: { xs: '2rem', sm: 'inherit' } }}
        >
          Services
        </Typography>
        <ListContainer>
          <Typography variant="h3" sx={{ fontSize: { xs: '1.25rem', sm: 'inherit' } }}>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>Slab</li>
              <li>Rough</li>
              <li>Trim Plumbing</li>
              <li>Water Line Repair</li>
              <li>Water Line Replacement</li>
              <li>Sewer Line Inspection</li>
              <li>Camera Line Inspection</li>
            </ul>
          </Typography>
        </ListContainer>
        {/* Grid container for service items */}
        <Grid container spacing={4} justifyContent="center">
          {/* Add service items as needed */}
        </Grid>
      </Container>
    </ServiceSection>
  );
};

export default Service;
