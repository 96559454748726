// src/components/URLListPage/index.jsx
import {
    Box,
    Container,
    Typography,
    useTheme,
    List,
    ListItem,
    ListItemText,
    Divider,
    useMediaQuery,
} from '@mui/material';
import { tokens } from '../../theme';

const URLListPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Example URLs
    const devUrls = [
        { label: 'Home', link: 'https://dev.riseplumbinginc.net' },
        { label: 'API', link: 'https://dev.riseplumbinginc.net/api' },
        { label: 'MinIO Storage UI', link: 'https://dev.minio.riseplumbinginc.net' },
        { label: 'MinIO API', link: 'https://dev.s3.riseplumbinginc.net' },
        { label: 'Database UI', link: 'https://dev.pgadmin.riseplumbinginc.net' },
        { label: 'Celery UI', link: 'https://dev.flower.riseplumbinginc.net' },
        { label: 'Rabbit UI', link: 'https://dev.rabbit.riseplumbinginc.net' },
        { label: 'Redis UI', link: 'https://dev.redisinsight.riseplumbinginc.net' },
    ];

    const prodUrls = [
        { label: 'Home', link: 'https://riseplumbinginc.net' },
        { label: 'API', link: 'https://riseplumbinginc.net/api' },
        { label: 'Database UI', link: 'https://pgadmin.riseplumbinginc.net' },
        { label: 'Celery UI', link: 'https://flower.riseplumbinginc.net' },
        { label: 'Rabbit UI', link: 'https://rabbit.riseplumbinginc.net' },
        { label: 'Redis UI', link: 'https://redisinsight.riseplumbinginc.net' },
    ];

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: isSmallScreen ? 'center' : 'left',
                    }}
                >
                    <Typography component="h1" variant="h4" gutterBottom>
                        Useful Links
                    </Typography>

                    {/* Development Environment URLs */}
                    <Typography variant="h5" sx={{ mt: theme.spacing(2), mb: theme.spacing(1) }}>
                        Development Environment
                    </Typography>
                    <List>
                        {devUrls.map((url, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    padding: theme.spacing(1, 0),
                                    borderBottom: `1px solid ${colors.grey[300]}`,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <a
                                            href={url.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: 'none',
                                                color: colors.blueAccent[700],
                                                fontWeight: 'bold',
                                                transition: 'color 0.3s ease',
                                            }}
                                            onMouseEnter={(e) =>
                                                (e.target.style.color = colors.blueAccent[300])
                                            }
                                            onMouseLeave={(e) =>
                                                (e.target.style.color = colors.blueAccent[700])
                                            }
                                        >
                                            {url.label}
                                        </a>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Divider sx={{ my: theme.spacing(3), borderColor: colors.greenAccent[700] }} />

                    {/* Production Environment URLs */}
                    <Typography variant="h5" sx={{ mt: theme.spacing(2), mb: theme.spacing(1) }}>
                        Production Environment
                    </Typography>
                    <List>
                        {prodUrls.map((url, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    padding: theme.spacing(1, 0),
                                    borderBottom: `1px solid ${colors.grey[300]}`,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <a
                                            href={url.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: 'none',
                                                color: colors.blueAccent[700],
                                                fontWeight: 'bold',
                                                transition: 'color 0.3s ease, text-decoration 0.3s ease',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.color = colors.blueAccent[300];
                                                e.target.style.textDecoration = 'underline';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.color = colors.blueAccent[700];
                                                e.target.style.textDecoration = 'none';
                                            }}
                                        >
                                            {url.label}
                                        </a>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </Container>
    );
};

export default URLListPage;
