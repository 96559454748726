// src/App.js

import { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom'; // Import useLocation
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import Team from './scenes/team';
import Invoices from './scenes/invoices';
import Bar from './scenes/bar';
import Form from './scenes/form';
import Line from './scenes/line';
import Pie from './scenes/pie';
import Property from './scenes/property';
import Geography from './scenes/geography';
import Inspection from './scenes/inspection';
import Landing from './scenes/landing';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import Calendar from './scenes/calendar';
import LoginPage from './scenes/login';
import People from './scenes/people';
import Warranty from './scenes/warranty';
import Document from './scenes/documents';
import Work from './scenes/work';
import UnauthorizedPage from './scenes/unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import useAuth from './hooks/useAuth';
import ResetPassword from './scenes/login/password_reset';
import VerifyCode from './scenes/login/password_reset/verify';
import SetNewPassword from './scenes/login/password_reset/new_password';
import ProfilePage from './scenes/profile';
import ManageAdminPage from './scenes/admin';
import URLListPage from './scenes/urls';
import TodoListPage from './scenes/todo';

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { auth } = useAuth();
    const isLoggedIn = !!auth?.accessToken;
    const location = useLocation(); // Get current location
    const isLandingPage = location.pathname === '/';

    // Array of paths where the Sidebar should not be rendered
    const excludeSidebarPaths = [
        '/login',
        '/password-reset',
        '/password-reset/verify',
        '/password-reset/new-password',
        '/', // If Landing page should not have Sidebar
        '/unauthorized',
    ];

    // Function to check if the current path is excluded from Sidebar
    const isExcludedPath = excludeSidebarPaths.some(
        (path) => location.pathname === path || location.pathname.startsWith(path + '/')
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setIsSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Close sidebar when navigating to an excluded path
    useEffect(() => {
        if (isExcludedPath) {
            setIsSidebarOpen(false);
        }
    }, [isExcludedPath]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {isLandingPage ? (
                  // Render Landing Page Separately
                  <Landing />
                ) : (
                <div className="app">
                    {/* Conditionally render Topbar */}
                    {isLoggedIn && !isExcludedPath && (
                        <Topbar
                            toggleSidebar={toggleSidebar}
                            isMobile={isMobile}
                        />
                    )}

                    {/* Conditionally render Sidebar for Desktop */}
                    {isLoggedIn && !isExcludedPath && !isMobile && (
                        <Sidebar
                            isSidebarOpen={isSidebarOpen}
                            isMobile={isMobile}
                            toggleSidebar={toggleSidebar}
                        />
                    )}

                    {/* Conditionally render Sidebar for Mobile */}
                    {isLoggedIn && !isExcludedPath && isMobile && isSidebarOpen && (
                        <Sidebar
                            isSidebarOpen={isSidebarOpen}
                            isMobile={isMobile}
                            toggleSidebar={toggleSidebar}
                        />
                    )}

                    <main className={`content ${isLoggedIn && !isExcludedPath ? 'with-topbar' : ''}`}>
                        <Routes>
                            <Route element={<PersistLogin />}>
                            <Route
                                element={
                                    <RequireAuth allowedRoles={['active']} />
                                }
                            >
                                {/* GOD PAGES */}
                                <Route
                                    element={
                                        <RequireAuth allowedRoles={['god']} />
                                    }
                                >
                                    <Route path="/admin-access" element={<ManageAdminPage />} />
                                    <Route path="/urls" element={<URLListPage />} />
                                    <Route path="/todo-list" element={<TodoListPage />} />
                                </Route>
                                {/* ADMIN PAGES */}
                                <Route
                                    element={
                                        <RequireAuth allowedRoles={['admin', 'god']} />
                                    }
                                >
                                    {/* ADMIN - CHARTS */}
                                    <Route path="/bar" element={<Bar />} />
                                    <Route path="/pie" element={<Pie />} />
                                    <Route path="/line" element={<Line />} />
                                    <Route
                                        path="/geography"
                                        element={<Geography />}
                                    />
                                    {/* ADMIN - HR */}
                                    <Route path="/form" element={<Form />} />
                                    {/* ADMIN - FINANCES */}
                                    <Route
                                        path="/invoices"
                                        element={<Invoices />}
                                    />
                                </Route>
                                {/* USER PAGES - GENERAL WORK */}
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/calendar"
                                    element={<Calendar />}
                                />
                                <Route path="/profile" element={<ProfilePage />} />
                                <Route
                                    path="/inspection"
                                    element={<Inspection />}
                                />
                                <Route
                                    path="/property"
                                    element={<Property />}
                                />
                                <Route
                                    path="/warranty"
                                    element={<Warranty />}
                                />
                                <Route
                                    path="/document"
                                    element={<Document />}
                                />
                                <Route
                                    path="/work"
                                    element={<Work />}
                                />
                                {/* USER - HR */}
                                <Route path="/people" element={<People />} />
                                <Route path="/team" element={<Team />} />
                            </Route>
                            </Route>
                            {/* PUBLIC PAGES */}
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/unauthorized"
                                element={<UnauthorizedPage />}
                            />
                            <Route path="/password-reset" element={<ResetPassword />} />
                            <Route path="/password-reset/verify" element={<VerifyCode />} />
                            <Route path="/password-reset/new-password" element={<SetNewPassword />} />
                        </Routes>
                    </main>
                </div>
                )}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );

}

export default App;
