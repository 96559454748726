import {
    Box,
    Container,
    Typography,
    Checkbox,
    useTheme,
} from '@mui/material';
import { tokens } from '../../theme';

const TodoListPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const todos = [
        { text: 'Make this list more dynamic', completed: false },
        { text: 'Create the list (lol)', completed: true },
    ];

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4" sx={{ mb: theme.spacing(2) }}>
                        Todo List
                    </Typography>

                    {/* Hardcoded Todo List */}
                    <Box sx={{ textAlign: 'left' }}>
                        {todos.map((todo, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mb: theme.spacing(2),
                                    padding: theme.spacing(1),
                                    bgcolor: colors.blueAccent[300],
                                    borderRadius: '4px',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Checkbox
                                        checked={todo.completed}
                                        disabled
                                        color="primary"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textDecoration: todo.completed
                                                ? 'line-through'
                                                : 'none',
                                            color: todo.completed
                                                ? colors.grey[500]
                                                : colors.grey[900],
                                        }}
                                    >
                                        {todo.text}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default TodoListPage;
