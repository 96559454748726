// Contact.jsx

import { Box, Grid, Typography, TextField, Button } from '@mui/material';

const sectionStyles = {
  padding: '1.5%',
};

const textContainerStyles = {
  padding: '2%',
  margin: '0 auto',
  maxWidth: { xs: '90%', sm: '60%' },
};

const formBoxStyles = {
  display: 'flex',
  flexDirection: 'column',
  padding: '2%',
  margin: '0 auto',
  maxWidth: { xs: '90%', sm: '60%' },
};

const labelStyles = {
  marginTop: '1%',
  fontSize: { xs: '1rem', sm: 'inherit' },
};

const inputStyles = {
  marginTop: '0.5%',
};

const buttonStyles = {
  marginTop: '2%',
  padding: '1%',
  fontSize: '1rem',
  backgroundColor: '#007BFF',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
};

const headerStyles = {
  marginBottom: '1%',
  fontSize: { xs: '2rem', sm: 'inherit' },
};

const paragraphStyles = {
  marginTop: '1%',
  fontSize: { xs: '1rem', sm: 'inherit' },
};

const Contact = () => {
  return (
    <Box component="section" id="contact" sx={sectionStyles}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box sx={textContainerStyles}>
            <Typography variant="h2" sx={headerStyles}>
              Contact Us
            </Typography>
            <Typography variant="body1" sx={paragraphStyles}>
              Your satisfaction is our priority. Reach out to us for any plumbing needs.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box component="form" sx={formBoxStyles}>
            <Typography component="label" sx={labelStyles}>
              Name:
            </Typography>
            <TextField type="text" variant="outlined" fullWidth sx={inputStyles} />
            <Typography component="label" sx={labelStyles}>
              Email:
            </Typography>
            <TextField type="email" variant="outlined" fullWidth sx={inputStyles} />
            <Typography component="label" sx={labelStyles}>
              Message:
            </Typography>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              sx={inputStyles}
            />
            <Button type="submit" variant="contained" sx={buttonStyles}>
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
