// About.jsx

import { Box, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import plaque from './plaque.png';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundImage: `url('${plaque}')`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: '90%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: '40%',
  },
  margin: 'auto',
  color: '#000000',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const SectionBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#a50808',
  padding: theme.spacing(4),
  textAlign: 'center',
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 347, // Restored fixed height for desktop
  [theme.breakpoints.down('sm')]: {
    height: 'auto', // Allow height to adjust on small screens
    padding: theme.spacing(2),
  },
}));

const styles = {
  missionHeading: {
    color: '#000000',
    fontWeight: 'bold',
    marginTop: '2.1%',
    marginBottom: '2.1%',
    fontSize: { xs: '1.5rem', sm: 'inherit' },
  },
  missionText: {
    color: '#000000',
    fontWeight: 'bold',
    margin: '0% 8%',
    marginBottom: '2.2%',
    fontSize: { xs: '1rem', sm: 'inherit' },
  },
  visionHeading: {
    color: '#000000',
    fontWeight: 'bold',
    marginTop: '1.2%',
    marginBottom: '2.2%',
    fontSize: { xs: '1.5rem', sm: 'inherit' },
  },
  visionText: {
    color: '#000000',
    fontWeight: 'bold',
    margin: '0% 8%',
    marginBottom: '3.2%',
    fontSize: { xs: '1rem', sm: 'inherit' },
  },
  gridContainer: {
    flexGrow: 1,
  },
  gridItem: {
    textAlign: 'center',
    padding: '1%',
    margin: '0 2%',
  },
  gridItemText: {
    fontWeight: 'normal',
    color: '#ffffff',
    fontSize: { xs: '1rem', sm: '1.2rem', md: 'inherit' },
  },
  sectionLabel: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: { xs: '2rem', sm: '3rem', md: '400%' },
    marginBottom: '1.8%',
    textDecoration: 'underline',
  },
};

const About = () => {
  return (
    <Box mt={0} mb={0} id="about-section">
      <Box sx={{ backgroundColor: '#f35106' }} display="flex" justifyContent="center">
        <StyledPaper>
          <Typography variant="h3" sx={styles.missionHeading} gutterBottom>
            Mission Statement
          </Typography>
          <Typography variant="h5" sx={styles.missionText} gutterBottom>
            We strive to be the premier plumbing partner for residential builders and developers,
            ensuring future homeowners enjoy peace of mind with dependable systems for clean water,
            safe wastewater removal, and contamination prevention. We provide efficient solutions,
            all marked by our unwavering commitment to reliability, quality, and excellence.
          </Typography>
          <Typography variant="h3" sx={styles.visionHeading} gutterBottom>
            Vision Statement
          </Typography>
          <Typography variant="h5" sx={styles.visionText}>
            Our goal is to become the top choice for residential builders across the nation by delivering precise slab,
            sewer, rough-in, and trim services, known for excellence, reliability, and ensuring peace of mind in every project we handle.
          </Typography>
        </StyledPaper>
      </Box>
      <SectionBox>
        <Typography variant="h1" sx={styles.sectionLabel} gutterBottom>
          About
        </Typography>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={styles.gridContainer}
        >
          <Grid item xs={12} md={2} sx={styles.gridItem}>
            <Typography variant="h3" sx={styles.gridItemText}>
              Rise Plumbing Inc is a family-owned business dedicated to dependable service you can trust.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} sx={styles.gridItem}>
            <Typography variant="h3" sx={styles.gridItemText}>
              Our plumbers are licensed, insured, and ready to take emergency calls around the clock.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} sx={styles.gridItem}>
            <Typography variant="h3" sx={styles.gridItemText}>
              To better serve our diverse community's plumbing needs, Se Habla Español!
            </Typography>
          </Grid>
        </Grid>
      </SectionBox>
    </Box>
  );
};

export default About;
