// src/scenes/global/Sidebar.jsx

import { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import DescriptionIcon from '@mui/icons-material/Description';
import WorkIcon from '@mui/icons-material/Work';
import useAuth from '../../hooks/useAuth';
import useAvatarUrl from '../../hooks/useAvatarUrl';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LinkIcon from '@mui/icons-material/Link';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const Sidebar = ({ isSidebarOpen, isMobile, toggleSidebar }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState('Dashboard');
    const { auth } = useAuth();
    const fetchAvatarUrl = useAvatarUrl();

    useEffect(() => {
        if (auth.user_id && !auth.avatarUrl) {
            fetchAvatarUrl(auth.user_id);
        }
    }, [auth.user_id, auth.avatarUrl, fetchAvatarUrl]);

    const checkUrlExpiration = () => {
        if (auth.avatarUrl) {
            try {
                const url = new URL(auth.avatarUrl);
                const amzDate = url.searchParams.get('X-Amz-Date');
                const amzExpires = parseInt(url.searchParams.get('X-Amz-Expires'), 10);

                if (amzDate && !isNaN(amzExpires)) {
                    const urlTimestamp = new Date(
                        Date.UTC(
                            parseInt(amzDate.slice(0, 4)),
                            parseInt(amzDate.slice(4, 6)) - 1,
                            parseInt(amzDate.slice(6, 8)),
                            parseInt(amzDate.slice(9, 11)),
                            parseInt(amzDate.slice(11, 13)),
                            parseInt(amzDate.slice(13, 15))
                        )
                    );

                    const expirationTime = new Date(urlTimestamp.getTime() + amzExpires * 1000);
                    const currentTime = new Date();
                    if (currentTime >= expirationTime) {
                        fetchAvatarUrl(auth.user_id);
                    }
                }
            } catch (error) {
                console.error('Error checking avatar URL expiration:', error);
            }
        }
    };

    useEffect(() => {
        if (auth.avatarUrl) {
            checkUrlExpiration();
            // Optionally, set up an interval to check periodically
            const interval = setInterval(checkUrlExpiration, 60000); // Check every minute
            return () => clearInterval(interval);
        }
    }, [auth.avatarUrl]);

    useEffect(() => {
        if (isMobile) {
            setIsCollapsed(false);
        }
    }, [isMobile]);

    const handleToggleCollapse = () => {
        if (isMobile) {
            toggleSidebar();
        } else {
            setIsCollapsed(!isCollapsed);
        }
    };

    // Extract user roles from auth
    // Handle both array and string
    let userRoles = [];
    if (Array.isArray(auth.roles)) {
        userRoles = auth.roles;
    } else if (typeof auth.roles === 'string') {
        userRoles = auth.roles.split(',').map(role => role.trim());
    } else {
        console.warn('auth.roles is neither an array nor a string:', auth.roles);
    }

    // Define menu items with associated roles and categories
    const menuItems = [
        // Dashboard is outside any category
        {
            title: "Dashboard",
            to: "/dashboard",
            icon: <HomeOutlinedIcon />,
            roles: ["admin", "active", "god"], // Accessible by admin and active roles
            category: null,
        },
        // Work Category
        {
            category: "Work",
            items: [
                { title: "Calendar", to: "/calendar", icon: <CalendarTodayOutlinedIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Warranties", to: "/warranty", icon: <RequestPageOutlinedIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Inspections", to: "/inspection", icon: <ManageSearchOutlinedIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Work", to: "/work", icon: <WorkIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Properties", to: "/property", icon: <HolidayVillageIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Documents", to: "/document", icon: <DescriptionIcon />, roles: ["admin", "active", "god"] },
            ],
        },
        // Financials Category
        {
            category: "Financials",
            items: [
                { title: "Invoices Balances", to: "/invoices", icon: <ReceiptOutlinedIcon />, roles: ["admin", "god"] },
            ],
        },
        // HR Category
        {
            category: "HR",
            items: [
                { title: "Manage People", to: "/people", icon: <PeopleOutlinedIcon />, roles: ["admin", "active", "god"] },
                { title: "Manage Team", to: "/team", icon: <PeopleOutlinedIcon />, roles: ["admin", "active", "god"] },
                { title: "Profile Form", to: "/form", icon: <PersonOutlinedIcon />, roles: ["admin", "god"] },
            ],
        },
        // Charts Category
        {
            category: "Charts",
            items: [
                { title: "Bar Chart", to: "/bar", icon: <BarChartOutlinedIcon />, roles: ["admin", "god"] },
                { title: "Pie Chart", to: "/pie", icon: <PieChartOutlineOutlinedIcon />, roles: ["admin", "god"] },
                { title: "Line Chart", to: "/line", icon: <TimelineOutlinedIcon />, roles: ["admin", "god"] },
                { title: "Geography Chart", to: "/geography", icon: <MapOutlinedIcon />, roles: ["admin", "god"] },
            ],
        },
        {
            category: "Ryan's Corner",
            items: [
                { title: "Grant Admin", to: "/admin-access", icon: <AdminPanelSettingsIcon />, roles: ["god"] },
                { title: "URLs", to: "/urls", icon: <LinkIcon />, roles: ["god"] },
                { title: "To-Do List", to: "/todo-list", icon: <FormatListNumberedIcon />, roles: ["god"] },
            ],
        },
    ];

    // Updated Item component with role-based access
    const Item = ({ title, to, icon, selected, setSelected, toggleSidebar, roles }) => {
        const theme = useTheme();
        const colors = tokens(theme.palette.mode);

        // Check if the user has at least one of the required roles
        const hasAccess = roles.some(role => userRoles.includes(role));

        if (!hasAccess) return null; // Do not render the item if the user lacks the required roles

        return (
            <MenuItem
                active={selected === title}
                style={{
                    color: colors.grey[100],
                }}
                onClick={() => {
                    setSelected(title);
                    if (toggleSidebar) {
                        toggleSidebar();
                    }
                }}
                icon={icon}
            >
                <Typography>{title}</Typography>
                <Link to={to} />
            </MenuItem>
        );
    };

    return (
        <Box
            sx={{
                display: isMobile && !isSidebarOpen ? 'none' : 'block',
                position: isMobile ? 'fixed' : 'relative',
                top: 0,
                left: 0,
                width: isCollapsed ? '80px' : '250px',
                height: '100vh',
                zIndex: isMobile ? 1300 : 1,
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed} toggled={isSidebarOpen}>
                <Menu iconShape="square">
                    {/* Collapse/Expand Menu */}
                    <MenuItem
                        onClick={handleToggleCollapse}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 20px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                >
                                    Rise Plumbing
                                </Typography>
                                <IconButton
                                    onClick={handleToggleCollapse}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {/* User Profile Section */}
                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={auth.avatarUrl}
                                    style={{
                                        cursor: 'pointer',
                                        borderRadius: '50%',
                                        objectFit: 'cover',
                                        display: 'block',
                                    }}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: '10px 0 0 0' }}
                                >
                                    {auth.name}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={colors.greenAccent[500]}
                                >
                                    {auth.job_title}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    {/* Sidebar Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                        {/* Standalone Items */}
                        {menuItems
                            .filter(menu => menu.category === null)
                            .map(menu => (
                                <Item
                                    key={menu.title}
                                    title={menu.title}
                                    to={menu.to}
                                    icon={menu.icon}
                                    selected={selected}
                                    setSelected={setSelected}
                                    toggleSidebar={toggleSidebar}
                                    roles={menu.roles}
                                />
                            ))}

                        {/* Categorized Items */}
                        {menuItems
                            .filter(menu => menu.category !== null)
                            .map(menu => {
                                // Filter items based on user roles
                                const accessibleItems = menu.items.filter(item =>
                                    item.roles.some(role => userRoles.includes(role))
                                );

                                // If no accessible items, do not render the category
                                if (accessibleItems.length === 0) return null;

                                return (
                                    <Box key={menu.category} sx={{ marginTop: '20px' }}>
                                        <Typography
                                            variant="h6"
                                            color={colors.grey[300]}
                                            sx={{ m: '15px 0 5px 20px' }}
                                        >
                                            {menu.category}
                                        </Typography>
                                        {accessibleItems.map(item => (
                                            <Item
                                                key={item.title}
                                                title={item.title}
                                                to={item.to}
                                                icon={item.icon}
                                                selected={selected}
                                                setSelected={setSelected}
                                                toggleSidebar={toggleSidebar}
                                                roles={item.roles}
                                            />
                                        ))}
                                    </Box>
                                );
                            })}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    );
}

    export default Sidebar;
