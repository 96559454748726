// Footer.jsx

import React from 'react';
import {
  Box,
  Typography,
  Link,
  IconButton,
  Grid,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from './logo.png';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#000000',
  color: '#ffffff',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const Logo = styled('img')(({ theme }) => ({
  maxHeight: 60,
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: '#ffffff',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const SocialMediaIcons = styled(Box)(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(0.5),
  },
}));

const Footer = () => {
  return (
    <FooterContainer component="footer">
      <Grid container spacing={4} justifyContent="center">
        {/* Company Logo and Description */}
        <Grid item xs={12} sm={6} md={3} textAlign={{ xs: 'center', md: 'left' }}>
          <Box display="flex" alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }}>
            <Logo src={logo} alt="Company Logo" />
          </Box>
          <Typography variant="body2" mt={2}>
            Rise Plumbing Inc is committed to providing reliable and quality plumbing services to our community.
          </Typography>
        </Grid>

        {/* Navigation Links */}
        <Grid item xs={12} sm={6} md={3} textAlign={{ xs: 'center', md: 'left' }}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Box display="flex" flexDirection="column">
            <FooterLink href="/">Home</FooterLink>
            <FooterLink href="#about-section">About Us</FooterLink>
            <FooterLink href="#services-section">Services</FooterLink>
            <FooterLink href="#contact">Contact</FooterLink>
            <FooterLink href="/dashboard">Login</FooterLink>
          </Box>
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12} sm={6} md={3} textAlign={{ xs: 'center', md: 'left' }}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2">
            Address: Chattanooga, TN
          </Typography>
          <Typography variant="body2">
            Phone: (423) 825-9505
          </Typography>
          <Typography variant="body2">
            Email: contact@riseplumbinginc.net
          </Typography>
        </Grid>

        {/* Social Media Icons */}
        <Grid item xs={12} sm={6} md={3} textAlign={{ xs: 'center', md: 'left' }}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          <SocialMediaIcons>
            <IconButton color="inherit" href="https://www.facebook.com" target="_blank" aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton color="inherit" href="https://www.twitter.com" target="_blank" aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
            <IconButton color="inherit" href="https://www.linkedin.com" target="_blank" aria-label="LinkedIn">
              <LinkedInIcon />
            </IconButton>
          </SocialMediaIcons>
        </Grid>
      </Grid>
      <Divider sx={{ backgroundColor: '#ffffff', marginY: 2 }} />
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()} Rise Plumbing Inc. All rights reserved.
      </Typography>
    </FooterContainer>
  );
};

export default Footer;
