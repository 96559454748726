// usePeopleData.js
import { useEffect, useState, useCallback } from 'react';
import useAxiosPrivate from './useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';

const usePeopleData = () => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [personData, setPersonData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const fetchPeople = useCallback(async () => {
        try {
            const controller = new AbortController();
            const response = await axiosPrivate.get('/person/persons', {
                signal: controller.signal,
            });
            const sortedData = response.data.sort((a, b) => a.id - b.id);
            setPersonData(sortedData);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login', {
                    state: { from: location },
                    replace: true,
                });
            } else {
                setLoading(false);
            }
        }
    }, [axiosPrivate, location, navigate]);

    useEffect(() => {
        fetchPeople();
    }, [fetchPeople]);

    return { loading, personData, fetchPeople };
};

export default usePeopleData;
