import {
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
    Modal,
    TextField,
    Chip,
    OutlinedInput,
    Checkbox,
    FormControl,
    InputLabel,
    IconButton,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import ImageUploadModal from './ImageUploadModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import { tokens } from '../../theme';
import { precedenceRoles } from './RoleManager';

const EditPersonModal = (props) => {
    const {
        isEditPersonModalOpen,
        setEditPersonModalOpen,
        selectedPersonForEdit,
        setSnackbarMessage,
        setSnackbarType,
        setSnackbarOpen,
        setSelectedPersonForEdit,
        editData,
        setEditData,
    } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);
    const [isActive, setIsActive] = useState(
        (editData?.roles ?? []).includes('active')
    );
    const [isRemoveChecked, setRemoveChecked] = useState(false);
    const [isImageSelected, setImageSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAvatarModalOpen, setAvatarModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsActive((editData?.roles ?? []).includes('active'));
    }, [editData]);

    const phoneRegExp = /^\(?\d{3}\)?-?\d{3}-?\d{4}$/;

    const checkoutSchema = yup.object().shape({
        first_name: yup
            .string()
            .matches(/^\S+$/, 'First name cannot contain spaces')
            .required('First name is required'),
        last_name: yup
            .string()
            .matches(/^\S+$/, 'Last name cannot contain spaces')
            .required('Last name is required'),
        username: yup.string().required('required'),
        job_title: yup.string().required('required'),
        email: yup.string().email('invalid email').required('required'),
        contact: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('required'),
        address1: yup.string().nullable().notRequired(),
        address2: yup.string().nullable().notRequired(),
    });

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const combinedRoles = buildCombinedRoles(values.roles, editData.roles);
            const isActiveChanged = (editData.roles || []).includes('active') !== isActive;
            const personDataToUpdate = {
                ...values,
                roles: combinedRoles,
            };
            const controller = new AbortController();

            if (selectedFile) {
                const dataUrl = selectedFile.toDataURL('image/png');
                const blob = await fetch(dataUrl).then((res) => res.blob());
                const formData = new FormData();
                formData.append('avatar', blob, 'cropped_image.png');
                await axiosPrivate.put(`/person/person/${selectedPersonForEdit}/avatar`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    signal: controller.signal,
                });
            }

            await axiosPrivate.put(`/person/person/${selectedPersonForEdit}`, personDataToUpdate, {
                signal: controller.signal,
            });

            if (isActiveChanged) {
                if (isActive) {
                    await axiosPrivate.patch(`/person/activate/${selectedPersonForEdit}`, {
                        signal: controller.signal,
                    });
                } else {
                    await axiosPrivate.patch(`/person/deactivate/${selectedPersonForEdit}`, {
                        signal: controller.signal,
                    });
                }
            }

            if (isRemoveChecked) {
                await axiosPrivate.delete(`/person/person/${selectedPersonForEdit}/avatar`, {
                    signal: controller.signal,
                });
            }

            setSnackbarMessage(`Updated ${values.username} successfully`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            closeModal();
        } catch (err) {
            if (err.response?.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(err.response?.data?.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const buildCombinedRoles = (roles, existingRoles) => {
        const safeExistingRoles = Array.isArray(existingRoles) ? existingRoles : [];
        const additionalRoles = safeExistingRoles.filter((role) => ['admin', 'active', 'god'].includes(role.toLowerCase()));
        return [...roles, ...additionalRoles];
    };

    const handleReset = (resetForm) => {
        resetForm();
        setIsActive((editData?.roles ?? []).includes('active'));
        setImageSelected(false);
        setSelectedFile(null);
        setRemoveChecked(false);
    };

    const handleResetPassword = async (values, resetForm) => {
        try {
            const controller = new AbortController();
            await axiosPrivate.patch(`/auth/person/${selectedPersonForEdit}/reset`, {
                signal: controller.signal,
            });
            setSnackbarMessage(`Password reset email sent to ${values.username}`);
            setSnackbarType('success');
            setSnackbarOpen(true);
            resetForm();
            closeModal();
        } catch (err) {
            if (err.response?.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setSnackbarMessage(err.response?.data?.message || 'Error occurred');
                setSnackbarType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleCloseAvatarModal = () => {
        setAvatarModalOpen(false);
    };

    const closeModal = () => {
        setEditPersonModalOpen(false);
        setSelectedPersonForEdit(null);
        setEditData('');
        setImageSelected(false);
        setSelectedFile(null);
        setRemoveChecked(false);
    };

    const handleSelectAction = (action, handleSubmit, resetForm, values) => {
        setSelectedAction(action);

        switch (action) {
            case 'Update User':
                handleSubmit(); // Trigger Formik's submit handler
                break;
            case 'Reset Password':
                handleResetPassword(values, resetForm); // Call the password reset handler
                break;
            case 'Reset':
                handleReset(resetForm); // Reset form fields
                break;
            case 'Close':
                closeModal(); // Close the modal
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    return (
        <Modal
            open={isEditPersonModalOpen}
            onClose={closeModal}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: `1px solid ${colors.grey[300]}`,
                    borderRadius: '8px',
                    width: isMobile ? '80%' : '40%',
                    height: isMobile ? '65%' : '60%',
                    boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                    backgroundColor: colors.primary[400],
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        p: 1,
                    }}
                >
                    <IconButton onClick={closeModal}>
                        <CloseIcon sx={{ color: colors.grey[100] }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pb: 1,
                    }}
                >
                    <Typography variant="h3" color={colors.grey[100]}>
                        Edit User
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        px: 2,
                        pb: 2,
                    }}
                >
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={{
                            first_name: editData?.first_name ?? '',
                            last_name: editData?.last_name ?? '',
                            username: editData?.username ?? '',
                            job_title: editData?.job_title ?? '',
                            email: editData?.email ?? '',
                            contact: editData?.contact ?? '',
                            address1: editData?.address1 ?? '',
                            address2: editData?.address2 ?? '',
                            roles: (editData?.roles ?? []).filter((role) => !['god', 'admin', 'active'].includes(role)),
                            isActive: (editData?.roles ?? []).includes('active'),
                        }}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            resetForm,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="20px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        '& > div': {
                                            gridColumn: isMobile ? 'span 4' : undefined, // Stack items vertically on mobile
                                        },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="First Name"
                                        name="first_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.first_name}
                                        error={touched.first_name && Boolean(errors.first_name)}
                                        helperText={touched.first_name && errors.first_name}
                                        sx={{ gridColumn: 'span 2' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Last Name"
                                        name="last_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.last_name}
                                        error={touched.last_name && Boolean(errors.last_name)}
                                        helperText={touched.last_name && errors.last_name}
                                        sx={{ gridColumn: 'span 2' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Job Title"
                                        name="job_title"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.job_title}
                                        error={touched.job_title && Boolean(errors.job_title)}
                                        helperText={touched.job_title && errors.job_title}
                                        sx={{ gridColumn: 'span 2' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Email"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: 'span 2' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Contact Number"
                                        name="contact"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.contact}
                                        error={touched.contact && Boolean(errors.contact)}
                                        helperText={touched.contact && errors.contact}
                                        sx={{ gridColumn: 'span 4' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Address 1"
                                        name="address1"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.address1}
                                        error={touched.address1 && Boolean(errors.address1)}
                                        helperText={touched.address1 && errors.address1}
                                        sx={{ gridColumn: 'span 4' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Address 2"
                                        name="address2"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.address2}
                                        error={touched.address2 && Boolean(errors.address2)}
                                        helperText={touched.address2 && errors.address2}
                                        sx={{ gridColumn: 'span 4' }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Username"
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        error={touched.username && Boolean(errors.username)}
                                        helperText={touched.username && errors.username}
                                        sx={{ gridColumn: 'span 2' }}
                                    />
                                    <FormControl variant="filled" sx={{ gridColumn: 'span 2' }}>
                                        <InputLabel id="role-chip-label">Roles</InputLabel>
                                        <Select
                                            labelId="role-chip-label"
                                            id="role-select-chips"
                                            fullWidth
                                            multiple
                                            value={values.roles}
                                            onChange={(event) => {
                                                handleChange({
                                                    target: {
                                                        name: 'roles',
                                                        value: event.target.value,
                                                    },
                                                });
                                            }}
                                            onBlur={handleBlur}
                                            input={<OutlinedInput id="role-select-chips" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {precedenceRoles
                                                .filter((role) => !['god', 'admin', 'active'].includes(role))
                                                .map((role) => (
                                                    <MenuItem key={role} value={role}>
                                                        {role}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumn: 'span 2' }}>
                                        <Typography variant="h6" color={colors.grey[100]}>
                                            Set Active
                                        </Typography>
                                        <Checkbox
                                            checked={isActive}
                                            onChange={(e) => setIsActive(e.target.checked)}
                                            sx={{ ml: 1 }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridColumn: 'span 2' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setAvatarModalOpen(true)}
                                            sx={{
                                                color: 'white',
                                                backgroundColor: colors.greenAccent[500],
                                                '&:hover': {
                                                    backgroundColor: colors.greenAccent[700],
                                                },
                                                mr: 1,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            <CloudUploadOutlinedIcon sx={{ mr: 1 }} />
                                            Upload
                                        </Button>
                                        {isImageSelected ? (
                                            <>
                                                <CheckCircleOutlineIcon
                                                    sx={{ color: colors.greenAccent[500], ml: 1 }}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        setImageSelected(false);
                                                        setSelectedFile(null);
                                                        setRemoveChecked(false);
                                                    }}
                                                    sx={{
                                                        color: colors.redAccent[500],
                                                        '&:hover': {
                                                            color: colors.redAccent[700],
                                                        },
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : editData?.avatar_path ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="h6" color={colors.grey[100]} sx={{ ml: 1 }}>
                                                    Remove photo
                                                </Typography>
                                                <Checkbox
                                                    checked={isRemoveChecked}
                                                    onChange={(e) => setRemoveChecked(e.target.checked)}
                                                    sx={{ ml: 1 }}
                                                />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 2,
                                        gap: 2,
                                        flexDirection: isMobile ? 'column' : 'row',
                                    }}
                                >
                                    {isMobile ? (
                                        <FormControl sx={{ width: '100%', alignSelf: 'center', borderRadius: '8px' }}>
                                            <Select
                                                value={selectedAction}
                                                onChange={(e) =>
                                                    handleSelectAction(e.target.value, handleSubmit, resetForm, values)
                                                }
                                                displayEmpty
                                                sx={{
                                                    color: colors.grey[100],
                                                    backgroundColor: colors.primary[400],
                                                    borderRadius: '8px',
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[300],
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: colors.grey[100],
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        color: colors.grey[100],
                                                    },
                                                    boxShadow: `0px 4px 8px ${colors.grey[400]}`,
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select an action
                                                </MenuItem>
                                                <MenuItem value="Update User">Update User</MenuItem>
                                                <MenuItem value="Reset Password">Reset Password</MenuItem>
                                                <MenuItem value="Reset">Reset</MenuItem>
                                                <MenuItem value="Close">Close</MenuItem>
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: colors.greenAccent[500],
                                                    '&:hover': {
                                                        backgroundColor: colors.greenAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Update User
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    color: colors.grey[100],
                                                    borderColor: colors.grey[100],
                                                    whiteSpace: 'nowrap',
                                                }}
                                                onClick={() => handleResetPassword(values, resetForm)}
                                            >
                                                Reset Password
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleReset(resetForm)}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor: colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Reset
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={closeModal}
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: colors.redAccent[500],
                                                    '&:hover': {
                                                        backgroundColor: colors.redAccent[700],
                                                    },
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                Close
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
                <ImageUploadModal
                    isAvatarModalOpen={isAvatarModalOpen}
                    onClose={handleCloseAvatarModal}
                    onImageSelect={setSelectedFile}
                    setImageSelected={setImageSelected}
                />
            </Box>
        </Modal>
    );
};

export default EditPersonModal;
