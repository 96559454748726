// Header.jsx

import React, { useState, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './logo.png';

const Logo = styled('img')(({ theme }) => ({
  maxHeight: 100,
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    maxHeight: 150,
  },
}));

const CompanyInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    alignItems: 'center',
  },
}));

const NavigationLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > *': {
    marginLeft: theme.spacing(2),
  },
  '& > *:hover': {
    color: '#f50e0e',
  },
}));

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const headerRef = useRef(null); // Reference to the header

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollToSection = (id) => (event) => {
    event.preventDefault();
    const section = document.getElementById(id);
    if (section && headerRef.current) {
      const headerHeight = headerRef.current.clientHeight;
      window.scrollTo({
        top: section.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navItems = [
    { label: 'About', id: 'about-section' },
    { label: 'Services', id: 'services-section' },
    { label: 'Contact', id: 'contact' },
    { label: 'Login', href: '/dashboard' },
  ];

  return (
    <AppBar ref={headerRef} position="sticky" sx={{ backgroundColor: '#000000' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left side */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            flexGrow: isDesktop ? 0 : 1,
          }}
          onClick={handleLogoClick}
        >
          <Logo src={logo} alt="Logo" />
          <CompanyInfo>
            <Typography variant="h2" noWrap sx={{ fontWeight: 'bold' }}>
              Rise Plumbing Inc
            </Typography>
            <Typography variant="subtitle1">(423) 825-9505</Typography>
          </CompanyInfo>
        </Box>

        {/* Right side */}
        {isDesktop ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NavigationLinks>
              <Button
                color="inherit"
                onClick={handleScrollToSection('about-section')}
                sx={{ fontSize: '1rem' }}
              >
                About
              </Button>
              <Button
                color="inherit"
                onClick={handleScrollToSection('services-section')}
                sx={{ fontSize: '1rem' }}
              >
                Services
              </Button>
              <Button
                color="inherit"
                onClick={handleScrollToSection('contact')}
                sx={{ fontSize: '1rem' }}
              >
                Contact
              </Button>
              <Button
              color="inherit"
              href="/dashboard"
              sx={{
                fontSize: '1rem',
                '&:hover': {
                  color: '#f50e0e',
                },
              }}
            >
              Login
            </Button>
            </NavigationLinks>
          </Box>
        ) : (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>
      {/* Drawer for Mobile Navigation */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {navItems.map((item) => (
            <ListItem
              button
              key={item.label}
              onClick={(event) => {
                setDrawerOpen(false);
                if (item.href) {
                  window.location.href = item.href;
                } else {
                  handleScrollToSection(item.id)(event);
                }
              }}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Header;
