import { useState } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    useTheme,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { tokens } from '../../theme';

const ManageAdminPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // State variables
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('success');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [action, setAction] = useState('grant');

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleActionChange = (event) => {
        setAction(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const controller = new AbortController();

            const response = await axiosPrivate.patch(
                '/roles/manage-admin',
                JSON.stringify({ current_username: username, action }),
                {
                    signal: controller.signal,
                }
            );

            setMessage('Action successful!');
            setMessageType('success');
            setSnackbarOpen(true);
            setUsername('');
            setAction('grant');
        } catch (err) {
            if (err.response?.status === 401) {
                navigate('/login', { state: { from: location }, replace: true });
            } else {
                setMessage(err.response?.data?.message || 'Error occurred');
                setMessageType('error');
                setSnackbarOpen(true);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: theme.spacing(8),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={messageType}
                        sx={{
                            width: '100%',
                            backgroundColor:
                                messageType === 'success'
                                    ? colors.greenAccent[600]
                                    : colors.redAccent[600],
                            color: 'white',
                        }}
                    >
                        <Typography variant="h6">{message}</Typography>
                    </Alert>
                </Snackbar>

                <Box
                    sx={{
                        border: `1px solid ${colors.grey[300]}`,
                        borderRadius: '8px',
                        padding: theme.spacing(4),
                        width: '100%',
                        boxShadow: `0px 4px 10px ${colors.grey[200]}`,
                        backgroundColor: colors.primary[400],
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4">
                        Manage Admin Role
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: theme.spacing(2), width: '100%' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={handleUsernameChange}
                        />
                        <FormControl fullWidth sx={{ mt: theme.spacing(2) }}>
                            <InputLabel id="action-label">Action</InputLabel>
                            <Select
                                labelId="action-label"
                                id="action"
                                value={action}
                                label="Action"
                                onChange={handleActionChange}
                            >
                                <MenuItem value="grant">Grant</MenuItem>
                                <MenuItem value="revoke">Revoke</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: theme.spacing(2),
                                mb: theme.spacing(2),
                                bgcolor: colors.blueAccent[700],
                                '&:hover': {
                                    bgcolor: colors.blueAccent[400],
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default ManageAdminPage;
