import {
    Snackbar,
    Alert,
    Box,
    Typography,
    useTheme,
    Button,
    Select,
    MenuItem,
} from '@mui/material';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import PeopleIcon from '@mui/icons-material/People';
import Header from '../../components/Header';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import MuiPagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import { calculateShowAddToTeamButton } from '../people/RoleManager';
import useTeamData from '../../hooks/useTeamData';
import AddTeamModal from './AddTeamModal';
import DeleteTeamModal from './DeleteTeamModal';
import ViewTeamModal from './ViewTeamModal';
import EditTeamModal from './EditTeamModal';
import CircularLoading from '../global/CircularLoading';

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { auth } = useAuth();
    const showAddToTeamButton = calculateShowAddToTeamButton(auth.roles);
    const { loading, dropdownData, fetchTeams } = useTeamData();
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [checkedTeams, setCheckedTeams] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [selectedTeamPersons, setSelectedTeamPersons] = useState([]);
    const [selectedTeamName, setSelectedTeamName] = useState('');
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);
    const [selectedAction, setSelectedAction] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1050);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResetButtonClick = () => {
        setCheckedTeams([]);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (snackbarOpen) {
            fetchTeams();
        }

        const closeSnackbarOnOutsideClick = (event) => {
            if (snackbarOpen && !event.target.closest('.MuiAlert-root')) {
                setSnackbarOpen(false);
            }
        };

        document.addEventListener('click', closeSnackbarOnOutsideClick);

        return () => {
            document.removeEventListener('click', closeSnackbarOnOutsideClick);
        };
    }, [snackbarOpen, fetchTeams]);

    const columns = [
        { field: 'id', headerName: 'ID', width: isMobile ? 100 : 200 },
        {
            field: 'name',
            headerName: 'Name',
            width: isMobile ? 150 : 200,
            cellClassName: 'name-column--cell',
        },
        { field: 'count', headerName: 'Count', flex: 1 },
        {
            field: 'view',
            headerName: 'View',
            width: 100,
            sortable: false,
            headerAlign: 'center',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    sx={{
                        color: 'white',
                        backgroundColor: colors.greenAccent[500],
                        '&:hover': {
                            backgroundColor: colors.greenAccent[700],
                        },
                    }}
                    startIcon={<PeopleIcon />}
                    onClick={() => handleViewClick(params.row.id)}
                >
                    View
                </Button>
            ),
        },
    ];

    const handleViewClick = (id) => {
        const selectedTeam = dropdownData.find((team) => team.id === id);
        const persons = selectedTeam ? selectedTeam.persons || [] : [];
        console.log(JSON.stringify(selectedTeam.persons))
        const name = selectedTeam ? selectedTeam.name || '' : '';
        setSelectedTeamPersons(persons);
        setSelectedTeamName(name);
        setViewModalOpen(true);
    };

    const handleEditClick = (id) => {
        const selectedTeam = dropdownData.find((team) => team.id === id);

        // Use local variables to ensure values are correctly set before updating the state
        const persons = selectedTeam ? selectedTeam.persons || [] : [];
        const name = selectedTeam ? selectedTeam.name || '' : '';
        const teamId = selectedTeam ? selectedTeam.id || '' : '';

        // Update state with local variables
        setSelectedTeamPersons(persons);
        setSelectedTeamName(name);
        setSelectedTeamId(teamId);

        // Open the modal
        setEditModalOpen(true);
    };


    const handleSelectAction = (event) => {
        const action = event.target.value;
        setSelectedAction(action);

        switch (action) {
            case 'Add Team':
                setAddModalOpen(true);
                break;
            case 'Delete Team':
                setDeleteModalOpen(true);
                break;
            case 'Reset':
                handleResetButtonClick();
                break;
            default:
                break;
        }

        setSelectedAction('');
    };

    const teamDataMapped = dropdownData.map((team) => {
        return {
            id: team.id,
            name: team.name,
            count: team.persons ? team.persons.length : 0,
        };
    });

    const CustomFooter = () => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const currentPage = useGridSelector(apiRef, gridPageSelector);
        const pageSize = apiRef.current.state.pagination.pageSize;
        const [selectedPageSize, setSelectedPageSize] = useState(
            pageSize || 10
        );

        const handleChangePageSize = (e) => {
            const newSize = parseInt(e.target.value, 10);
            setSelectedPageSize(newSize);
            apiRef.current.setPageSize(newSize);
        };

        const firstRowIndex = currentPage * selectedPageSize + 1;
        const lastRowIndex =
            (currentPage + 1) * selectedPageSize > apiRef.current.getRowsCount()
                ? apiRef.current.getRowsCount()
                : (currentPage + 1) * selectedPageSize;

        return (
            <Box
                display="flex"
                justifyContent={isMobile ? 'center' : 'space-between'}
                alignItems="center"
                p={isMobile ? 1 : 2}
                backgroundColor={colors.blueAccent[700]}
                height={isMobile ? 'auto' : '56px'}
                borderRadius="0 0 3px 3px"
                flexDirection={isMobile ? 'column' : 'row'}
            >
                {isMobile ? (
                    <>
                        {showAddToTeamButton && (
                            <FormControl
                                fullWidth
                                sx={{ mb: 2 }}
                                size="small"
                            >
                                <Select
                                    value={selectedAction}
                                    onChange={handleSelectAction}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select action' }}
                                    style={{
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    <MenuItem value="" disabled>
                                        Select an action
                                    </MenuItem>
                                    <MenuItem value="Add Team">Add Team</MenuItem>
                                    <MenuItem
                                        value="Delete Team"
                                        disabled={checkedTeams.length === 0}
                                    >
                                        Delete Team
                                    </MenuItem>
                                    <MenuItem value="Reset">Reset</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        {showAddToTeamButton && (
                            <Box display="flex" alignItems="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => setAddModalOpen(true)}
                                    sx={{
                                        color: 'white',
                                        backgroundColor: colors.greenAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.greenAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    <Typography variant="h6">Add Team</Typography>
                                </Button>
                                <Button
                                    onClick={() => setDeleteModalOpen(true)}
                                    sx={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        '&:disabled': {
                                            backgroundColor: colors.redAccent[800],
                                        },
                                        mb: isMobile ? 1 : 0,
                                        mr: isMobile ? 0 : 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={checkedTeams.length === 0}
                                >
                                    <Typography variant="h6">Delete Team</Typography>
                                </Button>
                                <Button
                                    onClick={handleResetButtonClick}
                                    sx={{
                                        display: 'block',
                                        color: 'white',
                                        backgroundColor: colors.redAccent[500],
                                        '&:hover': {
                                            backgroundColor: colors.redAccent[700],
                                        },
                                        mb: isMobile ? 1 : 0,
                                    }}
                                    variant="contained"
                                    color="primary"
                                >
                                    <Typography variant="h6">Reset</Typography>
                                </Button>
                            </Box>
                        )}
                        <Box
                            display="flex"
                            justifyContent="right"
                            alignItems="center"
                            mt={isMobile ? 2 : 0}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                Rows per page:
                            </Typography>
                            <FormControl
                                sx={{
                                    m: 1,
                                    minWidth: '80px',
                                }}
                                size="small"
                            >
                                <Select
                                    value={selectedPageSize}
                                    onChange={handleChangePageSize}
                                    style={{
                                        backgroundColor:
                                            selectedPageSize === ''
                                                ? colors.grey[500]
                                                : 'inherit',
                                        border: '1px solid rgba(255, 255, 255, 0.23)',
                                        color: 'inherit',
                                        borderRadius: theme.shape.borderRadius,
                                        padding: theme.spacing(0.5),
                                    }}
                                >
                                    {[10, 25, 50].map((pageSizeOption) => (
                                        <MenuItem
                                            key={pageSizeOption}
                                            value={pageSizeOption}
                                        >
                                            {pageSizeOption}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Typography variant="h6" color={colors.grey[100]} ml={1}>
                                {`${firstRowIndex}-${lastRowIndex} of ${apiRef.current.getRowsCount()}`}
                            </Typography>
                            <MuiPagination
                                color="primary"
                                count={pageCount}
                                page={currentPage + 1}
                                onChange={(event, newPage) => {
                                    apiRef.current.setPage(newPage - 1);
                                }}
                                sx={{
                                    '.Mui-selected': {
                                        backgroundColor: colors.blueAccent[900],
                                        color: colors.grey[100],
                                    },
                                    '& .MuiPaginationItem-root:hover': {
                                        backgroundColor: colors.blueAccent[800],
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header title="TEAM" subtitle="Managing the Team Members" />
            {loading ? (
                <CircularLoading isOpen={loading} />
            ) : (
                <>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarType}
                            sx={{
                                width: '100%',
                                backgroundColor:
                                    snackbarType === 'success'
                                        ? colors.greenAccent[600]
                                        : colors.redAccent[600],
                                color: 'white',
                            }}
                        >
                            <Typography variant="h6" color={colors.grey[100]}>
                                {snackbarMessage}
                            </Typography>
                        </Alert>
                    </Snackbar>
                    <Box
                        mt="40px"
                        height="75vh"
                        sx={{
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                fontSize: isMobile ? '0.8rem' : '1rem',
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none',
                            },
                            '& .name-column--cell': {
                                color: colors.greenAccent[300],
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: colors.blueAccent[700],
                                borderBottom: 'none',
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                backgroundColor: colors.primary[400],
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: 'none',
                                backgroundColor: colors.blueAccent[700],
                            },
                            '& .MuiCheckbox-root': {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                        }}
                    >
                        <DataGrid
                            checkboxSelection={showAddToTeamButton}
                            pagination
                            disableSelectionOnClick={(params) =>
                                params.field === 'view'
                            }
                            pageSizeOptions={[10, 25, 50]}
                            slots={{ footer: CustomFooter }}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10 },
                                },
                            }}
                            rows={teamDataMapped}
                            columns={columns}
                            onRowSelectionModelChange={(newSelection) => {
                                setCheckedTeams(newSelection);
                            }}
                            onCellClick={(params, event) => {
                                const isViewColumn = params.field === 'view';
                                const isButtonClick =
                                    event.target.tagName === 'BUTTON';

                                if (!isButtonClick && isViewColumn) {
                                    event.stopPropagation();
                                }
                            }}
                            onRowClick={(params, event) => {
                                const isCheckboxClick =
                                    event.target.tagName === 'INPUT' &&
                                    event.target.type === 'checkbox';
                                const isButtonClick =
                                    event.target.tagName === 'BUTTON';

                                if (
                                    !isCheckboxClick &&
                                    !isButtonClick &&
                                    showAddToTeamButton
                                ) {
                                    if (params.row) {
                                        handleEditClick(params.row.id);
                                    }
                                }
                            }}
                            rowSelectionModel={checkedTeams}
                        />
                        <ViewTeamModal
                            isViewModalOpen={isViewModalOpen}
                            setViewModalOpen={setViewModalOpen}
                            selectedTeamPersons={selectedTeamPersons}
                            setSelectedTeamPersons={setSelectedTeamPersons}
                            selectedTeamName={selectedTeamName}
                            setSelectedTeamName={setSelectedTeamName}
                        />
                        <AddTeamModal
                            isAddModalOpen={isAddModalOpen}
                            setAddModalOpen={setAddModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <DeleteTeamModal
                            isDeleteModalOpen={isDeleteModalOpen}
                            setDeleteModalOpen={setDeleteModalOpen}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            checkedTeams={checkedTeams}
                            setCheckedTeams={setCheckedTeams}
                        />
                        <EditTeamModal
                            isEditModalOpen={isEditModalOpen}
                            setEditModalOpen={setEditModalOpen}
                            selectedTeamPersons={selectedTeamPersons}
                            setSelectedTeamPersons={setSelectedTeamPersons}
                            setSnackbarMessage={setSnackbarMessage}
                            setSnackbarType={setSnackbarType}
                            setSnackbarOpen={setSnackbarOpen}
                            selectedTeamName={selectedTeamName}
                            setSelectedTeamName={setSelectedTeamName}
                            selectedTeamId={selectedTeamId}
                            setSelectedTeamId={setSelectedTeamId}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Team;
