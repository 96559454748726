// HeroSection.jsx

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import banner from './banner.png';
import reliability from './reliability.png';
import icon from './icon.png';
import customer from './customer.png';

const ImageBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  marginBottom: '1.7%',
});

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
}));

const HeroSection = () => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine the Typography variant based on screen size
  const textVariant = isSmDown ? 'h6' : 'h3';

  return (
    <Box id="hero-section">
      <ImageBox>
        <img
          src={banner}
          alt="About"
          style={{
            width: '100%',
            maxHeight: isSmDown ? 300 : 500,
            objectFit: 'cover',
          }}
        />
      </ImageBox>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        style={{
          height: isSmDown ? '20vh' : '27.5vh',
          paddingLeft: isSmDown ? '5%' : '15%',
          paddingRight: isSmDown ? '5%' : '15%',
          backgroundColor: '#faae10',
        }}
      >
        {/* First Grid Item */}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{
            height: isSmDown ? 'auto' : '70%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImageContainer>
            <img
              src={reliability}
              alt="Reliability"
              style={{
                width: '100%',
                maxWidth: isSmDown ? '70px' : '130px', // Adjust this value as needed
                height: 'auto',
                objectFit: 'contain',
                margin: '0 auto',
              }}
            />
          </ImageContainer>
          <Typography variant={textVariant} align="center">
            Reliability
          </Typography>
        </Grid>

        {/* Second Grid Item */}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{
            height: isSmDown ? 'auto' : '72%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImageContainer>
            <img
              src={icon}
              alt="Expertise"
              style={{
                width: '100%',
                maxWidth: isSmDown ? '70px' : '130px',
                height: 'auto',
                objectFit: 'contain',
                margin: '0 auto',
              }}
            />
          </ImageContainer>
          <Typography variant={textVariant} align="center">
            Expertise
          </Typography>
        </Grid>

        {/* Third Grid Item */}
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          style={{
            height: isSmDown ? 'auto' : '72%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImageContainer>
            <img
              src={customer}
              alt="Customer-centric"
              style={{
                width: '100%',
                maxWidth: isSmDown ? '70px' : '130px',
                height: 'auto',
                objectFit: 'contain',
                margin: '0 auto',
              }}
            />
          </ImageContainer>
          <Typography variant={textVariant} align="center">
            Customer-centric
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
