// RoleManager.jsx

export const precedenceRoles = ['god', 'admin', 'active']; // Define precedence order

export const getHighestPrecedenceRole = (roles) => {
    let highestRole = null;

    if (!roles || !roles.includes('active')) {
        return 'inactive'; // Return 'inactive' if roles is null
    }

    for (const role of precedenceRoles) {
        if (roles.includes(role)) {
            highestRole = role;
            break; // Exit loop if a role with higher precedence is found
        }
    }

    return highestRole || 'inactive'; // Return highest precedence role found or 'inactive'
};

export const hasAdminOrHigherAccess = (roles) => {
    if (!roles) {
        return false; // Return false if roles are null
    }

    // Get the highest precedence role of the user
    const highestRole = getHighestPrecedenceRole(roles);
    // Check if the highestRole is 'admin' or higher
    return (
        precedenceRoles.indexOf(highestRole) <= precedenceRoles.indexOf('admin')
    );
};

export const calculateShowAddToTeamButton = (authRoles) => {
    return hasAdminOrHigherAccess(authRoles || []);
};
