import React from 'react';
import {Box} from '@mui/material'
import Header from "./Header";
import HeroSection from "./HeroSection";
import About from "./About";
import Service from './Services';
import Contact from "./Contact";
import Footer from "./Footer";

const Landing = () => {
  return (
    <Box>
    <Header />
    <HeroSection />
    <About />
    <Service />
    <Contact />
    <Footer />
    </Box>
  );
};

export default Landing;
